body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 入力フォームのスタイル */
.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
}

/* ドロップダウンメニューのスタイル */
.react-datepicker__month-container {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* 日付選択部分のスタイル */
.react-datepicker__day,
.react-datepicker__month-text {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 2px;
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

/* 選択された日付のスタイル */
.react-datepicker__day--selected,
.react-datepicker__month-text--selected {
  background-color: #276ef1;
  color: white;
  border-radius: 50%;
}

/* 今日の日付のスタイル */
.react-datepicker__day--today,
.react-datepicker__month-text--today {
  border: 1px solid #276ef1;
}

/* ホバー時のスタイル */
.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  background-color: #f0f0f0;
}

/* 時間選択部分のスタイル */
.react-datepicker__time-container .react-datepicker__time {
  background-color: white;
  border-left: 1px solid #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 80px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-list-item {
  padding: 5px 10px;
  font-size: 14px;
}

.react-datepicker__time-list-item:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
